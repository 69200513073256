// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage";
import "channels";

import "bootstrap";

import _ from "lodash";

Rails.start();
// Turbolinks.start()
ActiveStorage.start();

$(function () {
  // Alerts should disappear after some time
  setTimeout(function () {
    $(".alert").slideUp(500);
  }, 15000);

  // The header dropdown menu should animate when it is shown and hidden
  var headerDropDown = document.getElementById("headerDropDownMenu");

  if (headerDropDown != null) {
    headerDropDown.addEventListener("show.bs.dropdown", function () {
      $(this).find(".dropdown-menu").first().stop(true, true).slideDown();
    });

    headerDropDown.addEventListener("hide.bs.dropdown", function (e) {
      e.preventDefault();
      $(this)
        .find(".dropdown-menu")
        .first()
        .stop(true, true)
        .slideUp(500, function () {
          $(".nav-link").removeClass("show");
          $(".nav-link").attr("aria-expanded", "false");
          $(".dropdown").removeClass("show");
          $(".dropdown-menu").removeClass("show");
          $(".dropdown")
            .find(".dropdown-toggle")
            .attr("aria-expanded", "false");
        });
    });
  }
});
